
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 150px; /* Set the fixed height of the footer here */
  background-color: #f5f5f5;
  text-align: left;
}

@media(min-width: 768px) {
  .footer {
    height: 140px;
  }
}


.map-container {
  cursor: crosshair;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 1px;
  width: calc(100vw - 250px);
  position: relative
}

.map-info-container {
  position: absolute;
  bottom: 0px;
  z-index: 999;
  width: 100%;
}

.map-info {
  background-color: #282c34;
  border-radius: 10px;
  padding: 10px;
  font-size: medium;
  margin: 0 10px;
}

#logo {
  color: #ffffff;
  font-size: 12px;
}
#logo img {
  height: 24px;
}
.navbar label {
  color: white;
  font-size: 12px;
  line-height: 10px;
}
.navbar-brand {
  padding-bottom: 0;
}
.navbar-brand img {
  height: 36px;
}


.user-guide-link, .user-guide-link:visited, .user-guide-link:hover {
  color: white;
  text-decoration: underline
}

/*
  this seems silly but okay
  https://stackoverflow.com/questions/51710700/react-datepicker-input-width-will-not-adjust-to-100
 */
.datetime-picker-parent__full-width,
.datetime-picker-parent__full-width > div.react-datepicker-wrapper,
.datetime-picker-parent__full-width > div > div.react-datepicker__input-container
.datetime-picker-parent__full-width > div > div.react-datepicker__input-container input {
  width: 100%
}
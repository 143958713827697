.cover {
    padding: 0 1.5rem;
}

.coverContainer {
    width: 100%;
    background-color: #212529;
    color: #fff;
    box-shadow: inset 0 0 5rem rgba(0,0,0,0.5);
    display: flex;
}

.coverInner {
    max-width: 42em;
    height: 100%;
    margin: auto;
    display: flex;
}

.coverHeading {
    margin-bottom: 1em;
}
.confidence-tooltip {
    position: relative;
    display: inline-block;
}

.react-datepicker-popper {
    z-index: 9999;
}

.confidence-tooltip .confidence-tooltiptext {
    visibility: hidden;
    width: 395px;
    background-color: #343a40;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    border-radius: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -202.5px;
}

.confidence-tooltip .confidence-tooltiptext::after {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #212529 transparent transparent transparent;
}

.confidence-tooltip:hover .confidence-tooltiptext {
    visibility: visible;
    opacity: 1;
}
